import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Template({ data, pageContext }) {
  const { markdownRemark: post } = data;
  const { next, prev } = pageContext;
  const site = data.site.siteMetadata;

  const deck =
    post.frontmatter.description.slice(-1) === "."
      ? post.frontmatter.description.slice(0, -1)
      : post.frontmatter.description;

  console.log(next);
  return (
    <Layout className="font-body pt-24 pb-12 px-4">
      <Helmet>
        <title>{post.frontmatter.title} | Jules Forrest</title>
        <meta property="og:type" content="article" />
        {post.frontmatter.title && (
          <meta property="og:title" content={post.frontmatter.title} />
        )}
        {post.frontmatter.date && (
          <meta
            property="article:published_time"
            content={post.frontmatter.date}
          />
        )}
        {post.frontmatter.image_src && (
          <meta name="twitter:card" content="summary_large_image" />
        )}
        {site.siteUrl && post.frontmatter.path && (
          <meta
            property="og:url"
            content={site.siteUrl + post.frontmatter.path}
          />
        )}
        {post.frontmatter.description && (
          <meta name="description" content={post.frontmatter.description} />
        )}
        {post.frontmatter.description && (
          <meta
            property="og:description"
            content={post.frontmatter.description}
          />
        )}
        {site.siteUrl &&
          post.frontmatter.image_src &&
          post.frontmatter.image_src.childImageSharp.original.src && (
            <meta
              property="og:image"
              content={
                site.siteUrl +
                post.frontmatter.image_src.childImageSharp.original.src
              }
            />
          )}
        {post.frontmatter.image_src &&
          post.frontmatter.image_src.childImageSharp.original.width && (
            <meta
              property="og:image:width"
              content={
                post.frontmatter.image_src.childImageSharp.original.width
              }
            />
          )}
        {post.frontmatter.image_src &&
          post.frontmatter.image_src.childImageSharp.original.height && (
            <meta
              property="og:image:height"
              content={
                post.frontmatter.image_src.childImageSharp.original.height
              }
            />
          )}
        {post.frontmatter.image_src &&
          post.frontmatter.image_src.internal.mediaType && (
            <meta
              property="og:image:type"
              content={post.frontmatter.image_src.internal.mediaType}
            />
          )}
        {post.frontmatter.image_alt && (
          <meta property="og:image:alt" content={post.frontmatter.image_alt} />
        )}
      </Helmet>
      <section className="container mx-auto">
        <Header to="/notes" />
        <article>
          <h1 className="headline font-display leading-none text-green ">
            {post.frontmatter.title}
          </h1>

          <p className="max-w-2xl text-2xl text-green opacity-75 py-4 md:py-8">
            {deck}
          </p>
          <time className="font-code text-gray-600">
            Published {post.frontmatter.date}
          </time>
          <hr className="divider border-gray-400" />
          <div
            dangerouslySetInnerHTML={{ __html: post.html }}
            className="max-w-2xl post-content "
          />
        </article>
        <hr className="divider border-gray-400 mb-0" />
        <div className="flex justify-between">
          <div className="w-2/4 flex flex-col border-r border-dashed border-gray-400">
            {prev.path && (
              <Link
                to={prev.path}
                className="text-green flex flex-col md:text-xl p-4 md:p-6 mr-4 mt-4 mb-4 bg-palegreen h-full rounded-sm"
              >
                <span className="metadata text-gray-700 mb-1">Previous</span>
                <h3 className="m-0 p-0 text-lg md:text-xl mt-auto">
                  {prev.title}{" "}
                </h3>
              </Link>
            )}
          </div>
          <div className="w-2/4 flex flex-col">
            {next && (
              <Link
                to={next.path}
                className="text-green flex flex-col md:text-xl p-4 md:p-6 ml-4 mt-4 mb-4 bg-palepeach h-full rounded-sm text-right"
              >
                <span className="metadata text-gray-700 mb-1">Next</span>
                <h3 className="m-0 p-0 text-lg md:text-xl mt-auto">
                  {next.title}{" "}
                </h3>
              </Link>
            )}
          </div>
        </div>
        <hr className="divider border-gray-400 mt-0" />
        <Footer />
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      timeToRead
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMM DD, YYYY")
        path
        description
        image_alt
        image_src {
          internal {
            mediaType
          }
          childImageSharp {
            original {
              width
              height
              src
            }
          }
        }
        title
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
